import React from "react";

// Customizable Area Start
import { Box } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import AuthComp from "./AuthComp.web";
import { pulseImg, flag } from "./assets";
import "./styles/form.css";
import { IoCloseCircleOutline, IoCheckmarkCircleOutline } from "react-icons/io5";
import { FaHourglassStart } from "react-icons/fa";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class CreateCountry extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    const typing = this.state.isValidPhone === null;
    const validPhone = this.state.isValidPhone === true;
    const invalidPhone  = this.state.isValidPhone === false;
    return (
      // Customizable Area Start
      // Required for all blocks
      <AuthComp
        navigation={undefined}
        id={""}
        factsBgImg={pulseImg}
        bgImgPosition={"static"}
        bgImgWidth={"auto"}
        bgImgLeftPosition={0}
        factsHeading={this.factsHeadingCountry}
        factsInfoText={this.factsInfoTextCountry}
        factsInfoText2={this.factsInfoText2Country}
        factsIcon2={<span aria-label="brain">&#129467;</span>}
        backBtn={true}
        goBack={this.goBack}
        heading1={this.heading1Country}
        heading3={this.heading3Country}
        heading1style={"headingTransform"}
        heading3style={"headingTransform"}
        onclick={this.createCountry}
        bottomError={this.state.errorPhone}
        popupError={this.state.error}
        warning={this.state.warning}
        form={
          <>
            <Box className="inputWrapper">
              <select
                name="country"
                id="country"
                value={this.state.country}
                onChange={(e) => this.handleCountryChange(e)}
                className="input"
                data-testid="country"
              >
                <option value={""} data-testid="defaultCountry">{this.placeholderCountry}</option>
                {this.state.countryData.map((country: any, index: number) => {
                  return (
                    <option
                      key={index.toString()}
                      value={country.attributes?.name}
                      data-testid={`${country.attributes?.name}-option`}
                    >
                      {country.attributes?.name}
                    </option>
                  );
                })}
              </select>
              <label
                htmlFor="country"
                className="label"
                data-testid="labelCountry"
              >
                {this.labelCountry}
              </label>
            </Box>

            {this.state.isUSA && (
              <Box className="inputWrapper">
                <select
                  name="state"
                  id="state"
                  value={this.state.state}
                  onChange={(e) => this.handleStateChange(e)}
                  className="input"
                  data-testid="state"
                >
                  <option value={""} data-testid="defaultState">{this.placeholderState}</option>
                  {this.state.stateData?.map(
                        (state: any, index: number) => {
                          return (
                            <option
                              key={index.toString()}
                              value={state?.id}
                              data-testid={`${state}-option`}
                            >
                              {state?.name}
                            </option>
                          );
                        }
                      )}
                </select>
                <label
                  htmlFor="state"
                  className="label"
                  data-testid="labelState"
                >
                  {this.labelState}
                </label>
              </Box>
            )}

            {this.state.countrySelected && (
              <Box className="inputWrapper">
                <div
                  className="phoneInputWrapper"
                  data-testid="phoneInputWrapper"
                >
                  <span className="flag">
                    <span
                      className="flag"
                      data-testid="flag"
                    >{this.state.countrySelected?.attributes?.emoji_flag}</span>
                  </span>
                  &nbsp;&nbsp;
                  <span className="code">
                    (<span data-testid="phoneCode">{this.state.phoneCode || "+1"}</span>
                    )
                  </span>
                  <span className="divider"></span>
                </div>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  maxLength={15}
                  value={this.state.phone}
                  placeholder={this.state.phonenumberFormat || "212-456-7890"}
                  onChange={(e) => this.handlePhoneChange(e)}
                  onBlur={() => this.validatePhone()}
                  className="input phoneInput"
                  style={{ borderColor: this.phoneStateColor }}
                  data-testid="phone"
                />
                <label style={{ color: this.phoneStateColor }} className="label" htmlFor="phone" data-testid="labelPhone">
                  {this.labelPhone}
                </label>

                {(typing && !!this.state.phone) && <span className="phoneIconWrapper typingWrapper"><FaHourglassStart className="phoneIcon typing" /></span>}
                {validPhone && <span className="phoneIconWrapper"><IoCheckmarkCircleOutline className="phoneIcon validPhone" /></span>}
                {invalidPhone && <span className="phoneIconWrapper"><IoCloseCircleOutline className="phoneIcon invalidPhone" /></span>}
              </Box>
            )}
          </>
        }
      />
      // Customizable Area End
    );
  }
}
