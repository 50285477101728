import React from "react";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import { bgEllipsis2, welcomeImg } from "./assets";
import { Box } from "@material-ui/core";
import './styles/authComp.css';
import AuthComp from "./AuthComp.web";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "../../email-account-registration/src/EmailAccountRegistrationController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailValidated extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      // Required for all blocks
        <AuthComp 
          navigation={undefined} 
          id={""}
          factsBgImg={bgEllipsis2}
          bgImgPosition={"static"}
          bgImgWidth={"auto"}
          bgImgLeftPosition={0}
          backBtn={false}
          goBack={this.goBack}
          heading1={this.headingWelcome}
          heading2={`${this.appName},`}
          heading3={`${this.state.name}!`}
          heading2style={"heading2style"}
          heading3style={"heading3style"}
          emailValidated={true}
          buttonText={this.btnTextPersonalise}
          onclick={this.gotToPersonalisePage}
          iconMsg={
            <Box className="validateEmailMsgContainer emailValidatedMsgContainer" data-testid="emailValidatedMsgContainer">
                <img src={welcomeImg} alt="medEdCloud-logo" className="welcomeImg" data-testid="welcomeImg" />
            </Box>
          }      
          />
       // Customizable Area End
    );
  }
}


