import React from "react";

// Customizable Area Start
import {
  medEdLogo,
  heroBg,
  phone,
  dynamicForm,
  checkIcon,
  featuresImg,
  person,
  schedule,
  adaptiveImg,
  adaptiveImg2,
  healthcare,
  knowledge,
  innovation,
  marketplace,
  research,
  testifier1,
  testifier2,
  testifier3,
  testimonialsBg,
  quote,
  pricingBg,
  facebook,
  instagram,
  xIcon,
  linkedin,
  youtube,
} from "./assets";
import {
  IoMenuOutline,
  IoCloseOutline,
  IoCheckmarkCircleOutline,
} from "react-icons/io5";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import "./styles/main.css";
import "./styles/about.css";
import "./styles/school.css";
import "./styles/faq.css";
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderNavlinksContainerStyle = () => {
    return this.state.showMenu
      ? "navlinks-container"
      : "navlinks-container navlinks-container-close";
  };

  renderMenuIcon = () => {
    return (
      <>
        {this.state.showMenu ? (
          <span className="menu-icon" data-testid="menuIconClose">
            <IoCloseOutline size={30} color={"#fff"} />
          </span>
        ) : (
          <span className="menu-icon" data-testid="menuIconOpen">
            <IoMenuOutline size={30} color={"#fff"} />
          </span>
        )}
      </>
    );
  };

  renderPricingBtnWrapperStyle = (pricingCategory: boolean) => {
    return pricingCategory ? "pricing-btn-wrapper" : "pricing-btn-wrapper2";
  };

  renderCardWrapperStyle = (category: boolean, categoryClass: string) => {
    return category
      ? `card-wrapper-active card-wrapper-active-${categoryClass}`
      : "card-wrapper";
  };

  renderButtonTextColor = (pricingCategory: boolean) => {
    return pricingCategory ? "#fff" : "#ffffff50";
  };

  renderFaqAnswerStyle = (faq: any) => {
    if (this.state.showFaq === "") {
      return "faq-answer"; // Return empty string if showFaq is empty
    }

    return faq == this.state.showFaq ? "faq-answer-active" : "faq-answer";
  };

  renderFaqArrowStyle = (faq: any) => {
    if (this.state.showFaq === "") {
      return "faq-arrow"; // Return empty string if showFaq is empty
    }
    return faq === this.state.showFaq ? "faq-arrow-active" : "faq-arrow";
  };

  renderFaqArrowIcon = (faq: any) => {
    if (this.state.showFaq === "") {
      return <IoIosArrowForward />; // Return empty string if showFaq is empty
    }
    return faq == this.state.showFaq ? (
      <IoIosArrowForward />
    ) : (
      <IoIosArrowDown />
    );
  };

  // renderFaqNumberStyle = (number: any) => {
  //   if (this.state.showFaq === "") {
  //     return ""; // Return empty string if showFaq is empty
  //   }
  //   return this.state.pageNo === number ? "faq-pageNo-active" : "faq-pageNo";
  // };
  // Customizable Area End

  render() {
    const schools = this.state.pricing === "school";
    const students = this.state.pricing === "student";
    const governments = this.state.pricing === "government_agency";
    const messageMaxLength = 500;
    const availableChar = messageMaxLength - this.state.message.length;


    return (
      // Customizable Area Start
      <div className="landing-container">
        <div className="navbar-container">
          <nav className="navbar" data-testid="navbar">
            <img
              src={medEdLogo}
              className="logo"
              alt="medEd-logo"
              data-testid="logo"
            />

            <div
              className={this.renderNavlinksContainerStyle()}
              data-testid="navlinksContainer"
            >
              <ul className="navlinks-wrapper" data-testid="navlinks-wraapper">
                <li
                  className="nav-links"
                  data-testid="about"
                  onClick={this.handleLinkClick}
                >
                  <a href="#about-us">About Us</a>
                </li>
                <li
                  className="nav-links"
                  data-testid="features"
                  onClick={this.handleLinkClick}
                >
                  <a href="#features-and-benefits">Features & Benefits</a>
                </li>
                <li
                  className="nav-links"
                  data-testid="schools-link"
                  onClick={this.handleLinkClick}
                >
                  <a href="#school">For Schools</a>
                </li>
                <li
                  className="nav-links"
                  data-testid="students-link"
                  onClick={this.handleLinkClick}
                >
                  <a href="#student">For Students</a>
                </li>
                <li
                  className="nav-links"
                  data-testid="government-link"
                  onClick={this.handleLinkClick}
                >
                  <a href="#government_agency">For Government</a>
                </li>
                <li
                  className="nav-links"
                  data-testid="testimonials-link"
                  onClick={this.handleLinkClick}
                >
                  <a href="#testimonials">Testimonials</a>
                </li>
                <li
                  className="nav-links"
                  data-testid="pricing-link"
                  onClick={this.handleLinkClick}
                >
                  <a href="#pricing">Pricing</a>
                </li>
                <li
                  className="nav-links"
                  data-testid="faq-link"
                  onClick={this.handleLinkClick}
                >
                  <a href="#faqs">FAQs</a>
                </li>
                <li
                  className="nav-links"
                  data-testid="contact-link"
                  onClick={this.handleLinkClick}
                >
                  <a href="#contact-us">Contact Us</a>
                </li>
              </ul>

              <div className="btn-divider-wrapper">
                <span className="btn-line" />
                <span className="btn-wrapper">
                  <button
                    className="login-btn"
                    data-testid="loginBtn"
                    onClick={this.goToLogin}
                  >
                    Login
                  </button>
                </span>
              </div>
            </div>
            <div
              className="menu-icon-wrapper"
              onClick={this.handleMenuIcon}
              data-testid="menuIconWrapper"
            >
              {this.renderMenuIcon()}
            </div>
          </nav>
        </div>

        <div className="main">
          <section className="hero">
            {this.state.indexPage?.map((content: any) => {
              return (
                <>
                  <img
                    src={heroBg}
                    alt=""
                    className="hero-bg"
                    data-testid="heroBg"
                  />
                  <div className="hero-content">
                    <div className="hero-top">
                      <div className="img-cover">
                        <img
                          src={content.image_url}
                          alt=""
                          className="hero-img"
                          data-testid="heroImg"
                        />
                      </div>

                      <div className="hero-text-wrapper">
                        <h1 className="hero-text">
                          {content.title.split(" ")[0]}
                          <br />
                          <em>
                            {content.title.split(" ")[1] +
                              " " +
                              content.title.split(" ")[2]}
                          </em>
                          <br />
                          {content.title.split(" ")[3] +
                            " " +
                            content.title.split(" ")[4]}
                          <em>
                            <br />
                            {content.title.split(" ")[5]}
                          </em>
                        </h1>
                        <button
                          onClick={this.goToSignup}
                          className="join-btn"
                          data-testid="joinBtn"
                        >
                          {content.button_message}
                        </button>
                      </div>
                    </div>
                    <div className="hero-footer">
                      <span
                        className="gradient-line"
                        data-testid="gradientLine"
                      />
                      <p data-testid="heroFooterText">{content.footer}</p>
                      <span
                        className="gradient-line"
                        data-testid="gradientLine"
                      />
                    </div>
                  </div>
                </>
              );
            })}
          </section>

          <section id="about-us" className="section">
            {this.state.aboutUsPage?.map((content: any) => {
              return (
                <>
                  <div className="about-top">
                    <p className="title-btn" data-testid="aboutBtn">
                      About Us
                    </p>
                    <div className="section-content">
                      <h2 className="about-heading">
                        {content.title.split(" ")[0] +
                          " " +
                          content.title.split(" ")[1]}
                      </h2>
                      <div className="intro">
                        <h2 className="gradient-text">
                          {content.title.split(" ")[2]}
                          <span style={{ fontWeight: 200 }}>

                            {content.title.split(" ").slice(3).join(" ")}
                          </span>
                        </h2>
                        <h2 className="glowing-text"> {content.quotes}</h2>
                      </div>
                      <span className="vertical-divider" />
                      <div
                        className="info-text-wrapper"
                        data-testid="infoTextWrapper"
                      >
                        <p className="info-text">
                          {content.middle_text.split(" ")[0] +
                            " " +
                            content.middle_text.split(" ")[1] +
                            " " +
                            content.middle_text.split(" ")[2] +
                            " " +
                            content.middle_text.split(" ")[3] +
                            " " +
                            content.middle_text.split(" ")[4]}{" "}
                          <span className="info-gradient1">
                            {content.middle_text.split(" ")[5] +
                              " " +
                              content.middle_text.split(" ")[6] +
                              " " +
                              content.middle_text.split(" ")[7] +
                              " " +
                              content.middle_text.split(" ")[8] +
                              " " +
                              content.middle_text.split(" ")[9] +
                              " " +
                              content.middle_text.split(" ")[10]}{" "}
                          </span>
                        </p>
                        <p className="info-text">
                          {content.middle_text.split(' ')[11] + ' '}
                          <span className="info-gradient2">
                            {content.middle_text.split(" ")[12] +
                              " " +
                              content.middle_text.split(" ")[13] +
                              " " +
                              content.middle_text.split(" ")[14] +
                              " " +
                              content.middle_text.split(" ")[15] +
                              " " +
                              content.middle_text.split(" ")[16] +
                              " " +
                              content.middle_text.split(" ")[17] +
                              " " +
                              content.middle_text.split(" ")[18]}{" "}
                          </span>
                        </p>
                        <p className="info-text">
                          {content.middle_text.split(' ')[19] + ' '}
                          <span className="info-gradient3">
                            {content.middle_text.split(" ").slice(20).join(" ")}
                          </span>

                        </p>
                      </div>
                    </div>
                  </div>
                  <img
                    src={phone}
                    alt="phone-icon"
                    className="phone-icon"
                    data-testid="phoneIcon"
                  />
                </>
              );
            })}
          </section>

          <section id="features-and-benefits" className="section">
            {this.state.featureBenefit1?.map((content: any) => {
              return (
                <div className="about-top">
                  <p className="title-btn" data-testid="featuresBtn">
                    Features & Benefits
                  </p>
                  <div className="section-content feature-content">
                    <p className="feature-heading" data-testid="featureHeading">
                      We&apos;re redefining medical learning through innovative
                      features designed for today's educational challenges.
                    </p>
                    <div className="intro">
                      <img
                        src={featuresImg}
                        alt=""
                        className="features-img"
                        data-testid="featuresImg"
                      />
                      <div
                        className="feature-intro-wrapper"
                        data-testid="featureIntroText"
                      >
                        <p className="feature-info-text">
                          {content.title.split(" ")[0] +
                            " " +
                            content.title.split(" ")[1] +
                            " " +
                            content.title.split(" ")[2]}
                        </p>
                        <p className="feature-info-text">
                          {content.title.split(" ")[3] +
                            " " +
                            content.title.split(" ")[4] +
                            " " +
                            content.title.split(" ")[5]}
                        </p>
                        <p className="feature-info-text">
                          {content.title.split(" ").slice(6).join(" ")}
                        </p>
                      </div>
                    </div>

                    <div
                      className="feature-outline"
                      data-testid="featureOutline"
                    >
                      <div className="feature-wrapper">
                        <div className="icon-wrapper">
                          <img
                            src={schedule}
                            alt="schedule-icon"
                            className="feature-icon"
                            data-testid="featureIcon"
                          />
                        </div>
                        <div className="feature-info">
                          <h3>{content.first_subtitle_title}</h3>
                          <p>{content.first_subtitle_text}</p>
                        </div>
                      </div>

                      <div className="feature-wrapper">
                        <div className="icon-wrapper">
                          <img
                            src={person}
                            alt="schedule-icon"
                            className="feature-icon"
                            data-testid="featureIcon"
                          />
                        </div>
                        <div className="feature-info">
                          <h3>{content.second_subtitle_title}</h3>
                          <p>{content.second_subtitle_text}</p>
                        </div>
                      </div>

                      <div className="feature-wrapper">
                        <div className="icon-wrapper">
                          <img
                            src={dynamicForm}
                            alt="schedule-icon"
                            className="feature-icon"
                            data-testid="featureIcon"
                          />
                        </div>
                        <div className="feature-info">
                          <h3>{content.third_subtitle_title}</h3>
                          <p>{content.third_subtitle_text}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </section>

          <section
            id="features-and-benefits"
            className="section feature2-section"
          >
            {this.state.featureBenefit2?.map((content: any) => {
              return (
                <div className="section-content feature2-content">
                  <div className="feature-intro-container2">
                    <div
                      className="feature-intro-wrapper2"
                      data-testid="featureIntroWrapper"
                    >
                      <p className="feature-info-text2">
                        {content.title.split(" ")[0] +
                          " " +
                          content.title.split(" ")[1] +
                          " " +
                          content.title.split(" ")[2]}
                      </p>
                      <p className="feature-info-text2">
                        {content.title.split(" ")[3] +
                          " " +
                          content.title.split(" ")[4] +
                          " " +
                          content.title.split(" ")[5]}
                      </p>
                      <p className="feature-info-text2">
                        {content.title.split(" ").slice(6).join(" ")}
                      </p>
                    </div>
                  </div>

                  <div
                    className="feature-outline2"
                    data-testid="featureOutline2"
                  >
                    <div className="feature-wrapper2">
                      <img
                        src={checkIcon}
                        alt="checkIcon-icon"
                        className="check-icon"
                      />

                      <p>{content.heading_first}</p>
                    </div>

                    <div className="feature-wrapper2">
                      <img
                        src={checkIcon}
                        alt="checkIcon-icon"
                        className="check-icon"
                      />
                      <p>{content.heading_second}</p>
                    </div>

                    <div className="feature-wrapper2">
                      <img
                        src={checkIcon}
                        alt="checkIcon-icon"
                        className="check-icon"
                      />
                      <p>{content.heading_third}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </section>
          {this.state.featureBenefit3?.map((content: any) => {
            return (
              <>
                <section
                  id="features-and-benefits"
                  className="section feature3-section"
                >
                  <p className="feature3-heading" data-testid="feature3Heading">
                    {content.heading}
                  </p>
                  <img
                    src={adaptiveImg}
                    alt="web-app-screen-shot"
                    className="adaptive-img"
                    data-testid="adpativeImg"
                  />
                  <div
                    className="feature3-info-wrapper"
                    data-testid="feature3IntroWrapper"
                  >
                    <p>{content.title}</p>
                    <p>
                      {content.sub_title.split(" ")[0] +
                        " " +
                        content.sub_title.split(" ")[1]}
                      <span style={{ fontWeight: 400 }}>
                        {content.sub_title.split(" ")[2]}
                      </span>{" "}
                      {content.sub_title.split(" ").slice(3).join(" ")}
                    </p>
                    <span className="vertical-divider" style={{ height: 40 }} />
                    <p className="small-info-text">{content.first_text}</p>
                    <p className="small-info-text">{content.second_text}</p>
                  </div>
                </section>

                <section
                  id="features-and-benefits"
                  className="section feature4-section"
                  data-testid="feature4Section"
                >
                  <img
                    src={adaptiveImg2}
                    alt=""
                    className="feature4-bg"
                    data-testid="feature4Bg"
                  />
                  <div className="feature4-wrapper">
                    <div className="feature4-text">
                      <h2>
                        <strong>
                          {content.first_description.split(" ")[0] +
                            " " +
                            content.first_description.split(" ")[1]}{" "}
                        </strong>
                        {content.first_description.split(" ")[2] +
                          " " +
                          content.first_description.split(" ")[3] +
                          " " +
                          content.first_description.split(" ")[4] +
                          " " +
                          content.first_description.split(" ")[5] +
                          " " +
                          content.first_description.split(" ")[6] +
                          " " +
                          content.first_description.split(" ")[7] +
                          " " +
                          content.first_description.split(" ")[8] +
                          " " +
                          content.first_description.split(" ")[9]}{" "}
                        <strong>
                          <em>{content.first_description.split(" ")[10]}</em>
                        </strong>
                        ,{" "}
                        <strong>
                          <em>{content.first_description.split(" ")[11]}</em>
                        </strong>
                        {content.first_description.split(" ")[12]}
                        <strong>
                          <em>{content.first_description.split(" ")[13]}</em>
                        </strong>{" "}
                        {content.first_description.split(" ").slice(14).join(" ")}
                      </h2>
                      <h2>
                        {content.second_description}
                        <br />{" "}
                      </h2>
                    </div>

                    <div className="feature4-outline">
                      <div className="feature4">
                        <img
                          src={knowledge}
                          alt="brain-icon"
                          className="feature-icons"
                          data-testid="feature4-icon"
                        />
                        <p>{content.services_name[0]}</p>
                      </div>
                      <div className="feature4">
                        <img
                          src={healthcare}
                          alt="vr-device-icon"
                          className="feature-icons"
                          data-testid="feature4-icon"
                        />
                        <p>{content.services_name[1]}</p>
                      </div>
                      <div className="feature4">
                        <img
                          src={marketplace}
                          alt="store-icon"
                          className="feature-icons"
                          data-testid="feature4-icon"
                        />
                        {content.services_name[2]}
                      </div>
                      <div className="feature4">
                        <img
                          src={research}
                          alt="microscope-icon"
                          className="feature-icons"
                          data-testid="feature4-icon"
                        />
                        {content.services_name[3]}
                      </div>
                      <div className="feature4">
                        <img
                          src={innovation}
                          alt="light-bulb-icon"
                          className="feature-icons"
                          data-testid="feature4-icon"
                        />
                        <p>{content.services_name[4]}</p>
                      </div>
                    </div>
                  </div>
                </section>
              </>
            );
          })}

          <section
            className="section school-section curvesection"
            data-testid="schoolSection"
          >
            {this.state.schools.map((content: any) => {
              let titles = content.title.split(' ')[0];

              return (
                <div className="section-container" id={content.content_type}>
                  <div className="section-wrapper">
                    <p className="title-btn school-btn" data-testid="schoolBtn">
                      For {titles}
                    </p>
                    <div className="school-heading">
                      <h2>{content.title}</h2>
                      <h2>{content.sub_title}</h2>
                    </div>
                    <p className="partners-info">{content.description}</p>

                    <div className="partner-btn-wrapper">
                      <button
                        onClick={this.goToSignup}
                        className="join-btn partner-btn"
                        data-testid="partnerBtn"
                      >
                        {content.button_content}
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </section>

          <section
            id="testimonials"
            className="section school-section testimonial-section"
            data-testid="testimonialstSection"
          >
            <img
              src={testimonialsBg}
              alt=""
              className="pulse-bg testimonial-bg"
            />
            <div className="testimonials-container">
              <p className="title-btn" data-testid="featuresBtn">
                Testimonials
              </p>
              <div className="testimonials-wrapper">
                <div className="testimonial-info">
                  <h2>
                    Our learners and institutions love
                    <span style={{ fontWeight: 600 }}> MedEd </span>{" "}
                    <span style={{ fontWeight: 300 }}>CLOUD</span>'s impact!
                  </h2>
                  <p>
                    price Our community of over{" "}
                    <span style={{ fontWeight: 600 }}>10,000</span> learners and
                    educators consistently report enhanced engagement, improved
                    outcomes, and a renewed passion for medical education.
                  </p>
                </div>

                <div className="testimonial-container">
                  <div className="testimonial-wrapper">
                    <div className="testimonial">
                      <img src={quote} alt="quote-icon" className="quote" />
                      <p className="testimonial-text">
                        MedEd CLOUD has transformed my study habits. The
                        interactive modules make learning so much more engaging.
                        I've never felt this confident about my medical studies
                        before!
                      </p>
                      <p className="testifier-details">
                        <span className="testifier-name">Emily Torres</span>
                        <span className="testifier-title"> . Student</span>
                      </p>
                    </div>
                    <div className="testifier-image">
                      <img src={testifier1} alt="" />
                    </div>
                  </div>

                  <div className="testimonial-wrapper">
                    <div className="testimonial">
                      <img src={quote} alt="quote-icon" className="quote" />
                      <p className="testimonial-text">
                        As a professor, I've seen a remarkable improvement in
                        class performance since we adopted MedEd CLOUD. It's not
                        just a tool; it's a game-changer for medical education.
                      </p>
                      <p className="testifier-details">
                        <span className="testifier-name">
                          Dr. James O'Brien
                        </span>
                        <span className="testifier-title">
                          {" "}
                          . Springfield Medical University
                        </span>
                      </p>
                    </div>
                    <div className="testifier-image">
                      <img src={testifier2} alt="" />
                    </div>
                  </div>

                  <div className="testimonial-wrapper">
                    <div className="testimonial">
                      <img src={quote} alt="quote-icon" className="quote" />
                      <p className="testimonial-text">
                        MedEd CLOUD aligns perfectly with our public health
                        training objectives. It's comprehensive, compliant, and
                        incredibly effective for our ongoing education programs.
                      </p>
                      <p className="testifier-details">
                        <span className="testifier-name">Sarah Nguyen</span>
                        <span className="testifier-title">
                          {" "}
                          . National Health Service (NHS) Education
                        </span>
                      </p>
                    </div>
                    <div className="testifier-image">
                      <img src={testifier3} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            id="pricing"
            className="section pricing-section"
            data-testid="pricingSection"
          >
            <img src={pricingBg} alt="" className="pulse-bg pricing-bg" />
            <div className="pricing-content-container">
              <div className="about-top">
                <p className="title-btn" data-testid="pricingBtn">
                  Pricing
                </p>
              </div>
              <div className="section-content pricing-content">
                <h2 className="pricing-heading">Discover your perfect plan</h2>
                <h2 className="pricing-heading2">
                  Affordable, Flexible, Just Right for You!
                </h2>

                <div
                  className="pricing-container"
                  data-testid="pricingContainer"
                >
                  <div
                    className="pricing-category-select"
                    data-testid="pricingCategory"
                  >
                    <span
                      className={this.renderPricingBtnWrapperStyle(schools)}
                      data-testid="pricingBtnWrapperSchool"
                    >
                      <button
                        style={{ color: this.renderButtonTextColor(schools) }}
                        className="pricing-btn"
                        data-testid="pricingBtnSchool"
                        onClick={() => this.handlePricing("school")}
                      >
                        Schools
                      </button>
                    </span>
                    <span
                      className={this.renderPricingBtnWrapperStyle(students)}
                      data-testid="pricingBtnWrapperStudent"
                    >
                      <button
                        style={{ color: this.renderButtonTextColor(students) }}
                        className="pricing-btn"
                        data-testid="pricingBtnStudent"

                        onClick={() => this.handlePricing("student")}
                      >
                        Students
                      </button>
                    </span>
                    <span
                      className={this.renderPricingBtnWrapperStyle(governments)}
                      data-testid="pricingBtnWrapperGovernment"
                    >
                      <button
                        style={{
                          color: this.renderButtonTextColor(governments),
                        }}
                        className="pricing-btn"
                        data-testid="pricingBtnGovernment"

                        onClick={() => this.handlePricing("government_agency")}
                      >
                        Governments
                      </button>
                    </span>
                  </div>

                  <div
                    className="category-card-container"
                    data-testid="categoryCardContainer"
                  >


                    {this.state.pricingLists.length > 0 && this.state.pricingLists
                      .filter(
                        (pricing: any) => pricing.content_type === "school"
                      )
                      .map((ele: any) => {
                        const { divClassName } = this.renderClasses(ele?.price_type);
                        return (
                          <div
                            className={`categoryprice content-type-school cat-${ele?.price_type} ${divClassName} ${this.renderCardWrapperStyle(
                              schools,
                              "school"
                            )}`
                            }
                            data-testid={ele?.price_type}
                            id={ele?.price_type}
                            onClick={() => this.handleClick(ele?.price_type)}


                          >
                            <div
                              className={`pricecat${ele?.price_type}`}
                              data-testid={`pricecat${ele?.price_type}`}
                            >
                              <div
                                className="card-top">
                                <div className="card-icon-wrapper">
                                  <div className="card-icon" />
                                </div>
                                <p className="cat-name">{ele?.price_type}</p>
                                <div className="price">
                                  <p className="cat-price">{ele?.price}</p>


                                </div>
                                <p className="ideal">{ele?.title}</p>
                              </div>
                              <span
                                className="horizontal-line"
                              />
                              <div
                                className="card-bottom"
                              >
                                {ele.text_content?.map((item: any, idx: any) => {
                                  return (
                                    <>
                                      <div className="cat-features-wrapper" data-testid="cat-features-wrapper">
                                        <IoCheckmarkCircleOutline

                                          className="cat-feature-icon"
                                        />
                                        <p className="cat-features" data-testid="cat-features">{item}</p>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                            <div />
                            <div />
                          </div>
                        );
                      })}

                    {this.state.pricingLists
                      .filter(
                        (pricing: any) => pricing.content_type === "student"
                      )
                      .map((ele: any) => {
                        const { divClassName } = this.renderClasses(ele?.price_type);
                        return (
                          <div
                            className={`categoryprice content-type-student cat-${ele?.price_type} ${divClassName} ${this.renderCardWrapperStyle(
                              students,
                              "student"
                            )}`}
                            data-testid={ele?.price_type}
                            id={ele?.price_type}
                            onClick={() => this.handleClick(ele?.price_type)}
                          >
                            <div className={`pricecat${ele?.price_type}`}>
                              <div
                                className="card-top">
                                <div className="card-icon-wrapper">
                                  <div className="card-icon" />
                                </div>
                                <p className="cat-name">{ele?.price_type}</p>
                                <div className="price">
                                  <p className="cat-price"> {ele.price}</p>

                                </div>

                                <p className="ideal titlemain">{ele?.title}</p>
                              </div>
                              <span
                                className="horizontal-line"
                              />
                              <div
                                className="card-bottom cb"
                              >
                                {ele.text_content?.map((item: any) => {
                                  return (
                                    <>
                                      <div data-testid="cat-features-wrapper" className="cat-features-wrapper">
                                        <IoCheckmarkCircleOutline
                                          className="cat-feature-icon"
                                        />
                                        <p className="cat-features">{item}</p>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        );
                      })}

                    {this.state.pricingLists
                      .filter(
                        (pricing: any) =>
                          pricing.content_type === "government_agency"
                      )
                      .map((ele: any) => {
                        const { divClassName } = this.renderClasses(ele?.price_type);
                        return (
                          <div
                            className={`categoryprice content-type-government-agency cat-${ele?.price_type} ${divClassName} ${this.renderCardWrapperStyle(
                              governments,
                              "govt"
                            )}`}
                            data-testid={ele?.price_type}

                            id={ele?.price_type}
                            onClick={() => this.handleClick(ele?.price_type)}
                          >
                            <div className={`pricecat${ele?.price_type}`}>
                              <div
                                className="card-top"

                              >
                                <div
                                  className="card-icon-wrapper"
                                >
                                  <div className="card-icon" />
                                </div>
                                <p className="cat-name">{ele?.price_type}</p>
                                <div className="price">
                                  <p className="cat-price"> {ele?.price}</p>
                                 
                                </div>
                                <p className="ideal">{ele?.title}</p>
                              </div>
                              <span
                                className="horizontal-line hr"
                              />
                              <div
                                className="card-bottom"
                              >
                                {ele.text_content?.map((item: any) => {
                                  return (
                                    <>
                                      <div className="cat-features-wrapper" data-testid="cat-features-wrapper">
                                        <IoCheckmarkCircleOutline

                                          className="cat-feature-icon"
                                        />
                                        <p className="cat-features">{item}</p>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            id="faqs"
            className="section school-section faq-section"
            data-testid="faqSection"
          >
            <div className="faqBg" />
            <div className="testimonials-container faq-container">
              <div className="testimonials-wrapper faq-wrapper">
                <div className="faq-content-container">
                  <p
                    style={{ alignSelf: "flex-start" }}
                    className="title-btn"
                    data-testid="faqBtn"
                  >
                    FAQs
                  </p>
                  <h2>
                    Find your <span style={{ fontWeight: 900 }}>answers</span>{" "}
                    here!
                  </h2>
                  <div className="faq-content-wrapper">
                    {this.state.loadQueAns
                      .slice((this.state.pageNo - 1) * 4, this.state.pageNo * 4)
                      .map((item: any, index: any) => {
                        return (
                          <div className="faq-content">
                            <div className="faq-question-wrapper">
                              <div className="faq-question">
                                <p className="question">{item.question}</p>
                                <span
                                  className={this.renderFaqArrowStyle(index)}
                                  onClick={() => this.handleFaqClick(index)}
                                  data-testid="faqExpandArrow"
                                >
                                  {this.renderFaqArrowIcon(index)}
                                </span>
                              </div>
                              <span className="gradient-border" />
                            </div>
                            <p
                              className={this.renderFaqAnswerStyle(index)}
                              data-testid="faq1Answer"
                            >
                              {item.answer}
                            </p>
                          </div>
                        );
                      })}
                  </div>
                  <div className="faqNumber">

                    {Array.from(
                      { length: Math.ceil(this.state.loadQueAns.length / 4) },
                      (_, index) => index + 1
                    ).map((num: any) => {
                      return (
                        <p
                          key={num}
                          data-testid={`faq${num}Number`}
                          onClick={() => this.handlePageNoClick(num)}
                          className={
                            num === this.state.pageNo
                              ? "faq-pageNo-active"
                              : "faq-pageNo"
                          }
                        >
                          {num}
                        </p>
                      );
                    })}
                  </div>
                </div>

                <div
                  id="contact-us"
                  className="testimonial-container contact-container"
                >
                  <p className="title-btn" data-testid="faqBtn">
                    Contact Us
                  </p>
                  <div className="contact-heading">
                    <h2>Looking for specifics?</h2>
                    <h2>Let&apos;s connect!</h2>
                  </div>
                  <div className="contact-form">
                    <div className="contact-input-wrapper">
                      <label
                        htmlFor="name"
                        className={`contact-label ${this.state.emailError ? "error-label-active" : ""
                          }`}
                      >
                        {" "}
                        Your name
                        <br />
                        <input
                          value={this.state.name}
                          id="name"
                          placeholder="Type your full name"
                          onChange={this.handleName}
                          className={`contact-input ${this.state.emailError ? "error-style-active" : ""
                            }`}
                          data-testid="nameInput"
                        />
                      </label>
                      <span
                        className={
                          this.state.nameError
                            ? "error-message-active"
                            : "error-message"
                        }
                        data-test-id="nameError"
                      >
                        {this.state.nameError}
                      </span>
                    </div>

                    <div className="contact-input-wrapper">
                      <label
                        htmlFor="email"
                        className={`contact-label ${this.state.emailError ? "error-label-active" : ""
                          }`}
                      >
                        {" "}
                        Your email address
                        <br />
                        <input
                          value={this.state.email}
                          id="email"
                          placeholder="Type your email here"
                          onChange={this.handleEmail}
                          className={`contact-input ${this.state.emailError ? "error-style-active" : ""
                            }`}
                          data-testid="emailInput"
                        />
                      </label>
                      <span
                        className={
                          this.state.emailError
                            ? "error-message-active"
                            : "error-message"
                        }
                        data-test-id="emailError"
                      >
                        {this.state.emailError}
                      </span>
                    </div>

                    <div className="contact-input-wrapper">
                      <label
                        htmlFor="message"
                        className={`contact-label ${this.state.emailError ? "error-label-active" : ""
                          }`}
                      >
                        Your message
                        <br />
                        <textarea
                          value={this.state.message}
                          style={{ resize: "none" }}
                          id="message"
                          placeholder="How can we assist you? Feel free to request a demo, inquire about detailed information, or ask for specific support."
                          onChange={this.handleMessage}
                          rows={9}
                          className={`contact-input ${this.state.emailError ? "error-style-active" : ""
                            }`}
                          data-testid="messageInput"
                        />
                      </label>
                      {this.state.messageCounter && (
                        <div
                          style={{ margin: "5px 30px 5px 0" }}
                          className="text-counter"
                        >
                          <p data-testid="text-counter">{`${availableChar}/${messageMaxLength}`}</p>
                        </div>
                      )}
                      <span
                        className={
                          this.state.messageError
                            ? "error-message-active"
                            : "error-message"
                        }
                        data-test-id="messageError"
                      >
                        {this.state.messageError}
                      </span>
                    </div>

                    <button
                      onClick={this.handleNameTrim}
                      className="join-btn contact-btn"
                      data-testid="contactBtn"
                    >
                      Send message
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div className="landing-page-footer">
          <div className="footer-top">
            <div className="footer-links-container">
              <div className="footer-links-wrapper">
                <h4 className="links-heading">Our Company</h4>
                <ul className="footer-links" data-testid="footer-links">
                  <li className="footer-link">
                    <a href="#about-us">About Us</a>
                  </li>
                  <li className="footer-link">
                    <a href="#features-and-benefits">Features & Benefits</a>
                  </li>
                  <li className="footer-link">
                    <a href="#testimonials">Testimonials</a>
                  </li>
                  <li className="footer-link">
                    <a href="#faqs">FAQs</a>
                  </li>
                  <li className="footer-link">
                    <a href="#contact-us">Contact-us</a>
                  </li>
                </ul>
              </div>
              <div className="footer-links-wrapper">
                <h4 className="links-heading">Our Services</h4>
                <ul className="footer-links" data-testid="footer-links">
                  <li className="footer-link">
                    <a href="#for-schools">For Schools</a>
                  </li>
                  <li className="footer-link">
                    <a href="#for-students">For Students</a>
                  </li>
                  <li className="footer-link">
                    <a href="#for-government">For Government</a>
                  </li>
                  <li className="footer-link">
                    <a href="#pricing">Pricing</a>
                  </li>
                </ul>
              </div>
              <div className="footer-links-wrapper">
                <h4 className="links-heading">Legal</h4>
                <ul className="footer-links" data-testid="footer-links">
                  <li
                    className="footer-link"
                    onClick={this.goToTermsAndConditions}
                  >
                    Security
                  </li>
                  <li
                    className="footer-link"
                    onClick={this.goToTermsAndConditions}
                  >
                    Privacy Policy
                  </li>
                  <li
                    className="footer-link"
                    onClick={this.goToTermsAndConditions}
                    data-testid="termsLink"
                  >
                    Terms and Conditions
                  </li>
                </ul>
              </div>
            </div>
            <div className="motto">
              <img
                src={medEdLogo}
                className="logo footer-logo"
                alt="medEd-logo"
                data-testid="logo"
              />
              <p>
                MedEd <span style={{ fontWeight: 300 }}>CLOUD</span> is your
                reliable partner for navigating the complexities of medical
                education, offering a seamless and interactive learning
                experience.
              </p>
            </div>
          </div>
          <span className="footer-divider" />
          <div className="social-links-container">
            <p className="footnote">2024 MedEd Cloud. All rights reserved.</p>
            <div className="social-links" data-testid="socialLinks">
              <a href="#" target="_blank" className="social-link-wrapper">
                <img src={facebook} alt="facebook-icon" className="sm-icons" />
              </a>
              <a href="#" target="_blank" className="social-link-wrapper">
                <img
                  src={instagram}
                  alt="instagram-icon"
                  className="sm-icons"
                />
              </a>
              <a href="#" target="_blank" className="social-link-wrapper">
                <img src={xIcon} alt="x-icon" className="sm-icons" />
              </a>
              <a href="#" target="_blank" className="social-link-wrapper">
                <img src={linkedin} alt="linkedin-icon" className="sm-icons" />
              </a>
              <a href="#" target="_blank" className="social-link-wrapper">
                <img src={youtube} alt="youtube-icon" className="sm-icons" />
              </a>
            </div>
          </div>
        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
