import React from "react";

// Customizable Area Start
import {
  checkboxMec1,
  checkboxMec2,
  checkboxMec,
  groupCurrentTime,
  groupLogo,
  groupOptions2x,
  groupPriority,
  groupSwitcher1,
  groupSwitcher,
  image2,
  image3,
  imageChevronLeftWhite,
  imageChevronLeft,
  imageChevronRightWhite,
  imageChevronRight,
  imageX2x,
  skipArrowDown,
  skipArrowRight,
  skipBarChart4Bars,
  skipCalendarMonth,
  skipExpandMore1,
  skipExpandMore,
  skipLecture,
  skipMask1_2x,
  skipMask2_2x,
  skipMask_2x,
  skipMeeting,
  skipMoreVert,
  skipNewLogo,
  skipNoteStack,
  skipSocial1,
  skipSocial2_2x,
  skipSocial3_2x,
  skipSocial,
  skipSubstract,
  skipTask,
  skipUnion1,
  skipUnion2,
  skipUnion3,
  skipUnion,
  skipVector,
  skipWordmark,
  skipWorld,
  viewVector1,
  viewVector2,
  viewVector,
  skipLocationLink,
  skipLocationOn,
  skipCircle,
  skipCalendar,
  groupHighTasksPriority,
  skipTaskSummarize,
  groupLowTasksPriority
} from './assets'; 
import "./global.css";
import "./Scheduling.css";
import Header from "../../catalogue1/src/Header.web";
import { LocalizationProvider } from "@mui/x-date-pickers";
// Customizable Area End

import SchedulingController, {
  Props,
} from "./SchedulingController";

export default class Scheduling extends SchedulingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // render header
  renderHeader = () => {
    return (
    <Header   navigation={this.props.navigation}
    id={"2"}/>
    )
  }

  // render footer
  renderFooter = () => {
    return (
      <footer className="group-footer">
        <div className="view-rectangle10" />
        <div className="view-vector-parent">
          <img
            className="view-vector-icon1"
            alt="viewVector1"
            src={viewVector1}
          />
          <div className="frame-wrapper12">
            <div className="frame-parent17">
              <div className="frame-parent18">
                <div className="textlabel-title-parent2">
                  <b className="textlabel-title8">OUR COMPANY</b>
                  <div className="textlabel-title-parent3">
                    <div className="textlabel-title9">About Us</div>
                    <div className="textlabel-title-parent4">
                      <div className="textlabel-title10">{`Features & Benefits`}</div>
                      <div className="textlabel-title-parent5">
                        <div className="textlabel-title11">Testimonials</div>
                        <div className="textlabel-title12">FAQs</div>
                        <div className="textlabel-title13">Contact Us</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="textlabel-title-parent6">
                  <b className="textlabel-title14">OUR SERVICES</b>
                  <div className="textlabel-title-parent7">
                    <div className="textlabel-title15">For Schools</div>
                    <div className="textlabel-title-parent8">
                      <div className="textlabel-title16">For Students</div>
                      <div className="textlabel-title17">For Government</div>
                      <div className="textlabel-title18">Pricing</div>
                    </div>
                  </div>
                </div>
                <div className="textlabel-title-parent9">
                  <b className="textlabel-title19">LEGAL</b>
                  <div className="textlabel-title-parent10">
                    <div className="textlabel-title20">Security</div>
                    <div className="textlabel-title-parent11">
                      <div className="textlabel-title21">Privacy Policy</div>
                      <div className="textlabel-title22">
                        Terms and Conditions
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-parent19">
                <div className="skip-newlogo-parent">
                  <img
                    className="skip-newlogo-icon"
                    alt="skipNewLogo"
                    src={skipNewLogo}
                  />
                  <div className="skip-wordmark-wrapper">
                    <img
                      className="skip-wordmark-icon"
                      alt="skipWordmark"
                      src={skipWordmark}
                    />
                  </div>
                </div>
                <div className="textlabel-title23">
                  <span className="meded">MedEd</span>
                  <span>{` `}</span>
                  <span className="cloud">CLOUD</span>
                  <span>
                    {" "}
                    is your reliable partner for navigating the complexities
                    of medical education, offering a seamless and interactive
                    learning experience.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="view-vector-group">
          <img
            className="view-vector-icon2"
            alt="viewVector2"
            src={viewVector2}
          />
          <div className="frame-wrapper13">
            <div className="frame-parent20">
              <div className="textlabel-title-wrapper3">
                <div className="textlabel-title24">
                  2024 MedEd Cloud. All rights reserved.
                </div>
              </div>
              <div className="frame-parent21">
                <div className="view-elipse-parent">
                  <div className="view-elipse" />
                  <img
                    className="skip-social-icon"
                    alt="facebookIcon"
                    src={skipSocial}
                  />
                </div>
                <div className="view-elipse-group">
                  <div className="view-elipse1" />
                  <img
                    className="skip-social-icon1"
                    alt="instagramIcon"
                    src={skipSocial1}
                  />
                </div>
                <div className="view-elipse-container">
                  <div className="view-elipse2" />
                  <img
                    className="image-x-icon"
                    alt="xIcon"
                    src={imageX2x}
                  />
                </div>
                <div className="view-elipse-parent1">
                  <div className="view-elipse3" />
                  <img
                    className="skip-social-icon2"
                    alt="linkedinIcon"
                    src={skipSocial2_2x}
                  />
                </div>
                <div className="view-elipse-parent2">
                  <div className="view-elipse4" />
                  <img
                    className="skip-social-icon3"
                    alt="youtubeIcon"
                    src={skipSocial3_2x}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }

  // render calendar
  renderCalendarCard = () => {
    return (
      <div className="view-rectangle calendar-simple">
        <div className="group-header">
          <div className="calendar-simple-header">
            <b>February, 2024</b>
            <div className="calnder-switcher">
              <img
                width="24px"
                height="24px"
                src={imageChevronLeftWhite}
              />
              <img
                width="24px"
                height="24px"
                src={imageChevronRightWhite}
              />
            </div>
          </div>
        </div>
        <div className="group-calendar-days">
          <div className="group-weekday-parent">
            <div className="group-weekday">
              <div className="textlabel-">Mo</div>
            </div>
            <div className="group-weekday1">
              <div className="textlabel-1">Tu</div>
            </div>
            <div className="group-weekday2">
              <div className="textlabel-2">We</div>
            </div>
            <div className="group-weekday3">
              <div className="textlabel-3">Th</div>
            </div>
            <div className="group-weekday4">
              <div className="textlabel-4">Fr</div>
            </div>
            <div className="group-weekday5">
              <div className="textlabel-5">Sa</div>
            </div>
            <div className="group-weekday6">
              <div className="textlabel-6">Su</div>
            </div>
          </div>
          <div className="just-day">
            <div className="view-136" />
            <div className="day">4</div>
          </div>
          <div className="group-calendar-days-inner">
            <div className="group-calendarelementsday-parent">
              <div className="group-calendarelementsday">
                <div className="bg" />
                <div className="textlabel-day">29</div>
              </div>
              <div className="group-calendarelementsday1">
                <div className="bg1" />
                <div className="textlabel-day1">30</div>
              </div>
              <div className="group-calendarelementsday2">
                <div className="textlabel-day2">31</div>
              </div>
              <div className="group-calendarelementsday3">
                <div className="textlabel-day3">1</div>
              </div>
              <div className="group-calendarelementsday4">
                <div className="textlabel-day4">2</div>
              </div>
              <div className="group-calendarelementsday5">
                <div className="textlabel-day5">3</div>
              </div>
              <div className="group-calendarelementsday6">
                <div className="textlabel-day6">4</div>
              </div>
              <div className="group-calendarelementsday7">
                <div className="textlabel-day7">5</div>
              </div>
              <div className="group-calendarelementsday8">
                <div className="textlabel-day8">6</div>
              </div>
              <div className="group-calendarelementsday9">
                <div className="textlabel-day9">7</div>
              </div>
              <div className="group-today">
                <div className="textlabel-day10">8</div>
              </div>
              <div className="group-calendarelementsday10">
                <div className="textlabel-day11">9</div>
                <img className="indicator-icon" alt="" />
                <img className="indicator-icon1" alt="" />
              </div>
              <div className="group-calendarelementsday11">
                <div className="textlabel-day12">10</div>
                <img className="indicator-icon2" alt="" />
                <img className="indicator-icon3" alt="" />
              </div>
              <div className="group-calendarelementsday12">
                <div className="textlabel-day13">11</div>
                <img className="indicator-icon4" alt="" />
                <img className="indicator-icon5" alt="" />
                <img className="indicator-icon6" alt="" />
              </div>
              <div className="group-calendarelementsday13">
                <div className="textlabel-day14">12</div>
                <img className="indicator-icon7" alt="" />
                <img className="indicator-icon8" alt="" />
              </div>
              <div className="group-calendarelementsday14">
                <div className="textlabel-day15">13</div>
                <img className="indicator-icon9" alt="" />
              </div>
              <div className="group-calendarelementsday15">
                <div className="textlabel-day16">14</div>
                <img className="indicator-icon10" alt="" />
                <img className="indicator-icon11" alt="" />
                <img className="indicator-icon12" alt="" />
              </div>
              <div className="group-calendarelementsday16">
                <div className="textlabel-day17">15</div>
                <img className="indicator-icon13" alt="" />
                <img className="indicator-icon14" alt="" />
              </div>
              <div className="group-calendarelementsday17">
                <div className="textlabel-day18">16</div>
                <img className="indicator-icon15" alt="" />
              </div>
              <div className="group-calendarelementsday18">
                <div className="textlabel-day19">17</div>
                <img className="indicator-icon16" alt="" />
                <img className="indicator-icon17" alt="" />
              </div>
              <div className="group-calendarelementsday19">
                <div className="textlabel-day20">18</div>
              </div>
              <div className="group-calendarelementsday20">
                <div className="textlabel-day21">19</div>
              </div>
              <div className="group-calendarelementsday21">
                <div className="textlabel-day22">20</div>
                <img className="indicator-icon18" alt="" />
                <img className="indicator-icon19" alt="" />
                <img className="indicator-icon20" alt="" />
              </div>
              <div className="group-calendarelementsday22">
                <div className="textlabel-textlabel-day">
                  21
                </div>
              </div>
              <div className="group-calendarelementsday23">
                <div className="textlabel-day23">22</div>
                <img className="indicator-icon21" alt="" />
                <img className="indicator-icon22" alt="" />
              </div>
              <div className="group-calendarelementsday24">
                <div className="textlabel-day24">23</div>
              </div>
              <div className="group-calendarelementsday25">
                <div className="textlabel-day25">24</div>
              </div>
              <div className="group-calendarelementsday26">
                <div className="textlabel-day26">25</div>
                <img className="indicator-icon23" alt="" />
              </div>
              <div className="group-calendarelementsday27">
                <div className="textlabel-day27">26</div>
              </div>
              <div className="group-calendarelementsday28">
                <div className="textlabel-day28">27</div>
                <img className="indicator-icon24" alt="" />
                <img className="indicator-icon25" alt="" />
              </div>
              <div className="group-calendarelementsday29">
                <div className="textlabel-day29">28</div>
              </div>
              <div className="group-calendarelementsday30">
                <div className="textlabel-day30">29</div>
                <img className="indicator-icon26" alt="" />
              </div>
              <div className="group-calendarelementsday31">
                <div className="textlabel-day31">1</div>
              </div>
              <div className="group-calendarelementsdayem">
                <div className="bg2" />
                <div className="textlabel-day32">2</div>
              </div>
              <div className="group-calendarelementsdayem1">
                <div className="bg3" />
                <div className="textlabel-day33">3</div>
              </div>
            </div>
          </div>
          <div className="view-144" />
        </div>
      </div>
    )
  }

  // render upcoming meetings card
  renderUpcomingMeetingsCard = () => {
    const upcomingMeetingsData = this.state.upcomingMeetingsData
    const next = true
    const dataPerPage = 3
    const currentPage = 1
    const lastIndex = currentPage * dataPerPage
    const firstIndex = lastIndex - dataPerPage
  
    // slice data in chunks of 3
    const meetingsList = upcomingMeetingsData && upcomingMeetingsData.slice(firstIndex, lastIndex)
  
    return (
      <div className="view-rectangle group-upcomingmeetings">
        <div className="textlabel-title-group">
          <div className="group-header">
            <div className="upcomingmeetings-header">
              <b>Upcoming Meetings</b>
              <div className="upcomingmeetings-switcher-btns">
                <img
                  width="24px"
                  height="24px"
                  src={imageChevronLeft}
                />
                { next ?
                  <img
                    width="24px"
                    height="24px"
                    src={imageChevronRight}
                  />
                :
                  <img
                    width="24px"
                    height="24px"
                    src={imageChevronRightWhite}
                  />
                }
              </div>
            </div>
          </div>
          <div className="upcomingmeetings-content">
            <div className="upcomingmeetings-list">
              { meetingsList && meetingsList.length > 0 ?
                meetingsList.map((meetingObj: any, index: number) => {
                  const meeting = meetingObj && meetingObj["attributes"]
                  const title = meeting && meeting["title"]
                  const link = meeting && meeting["link"]
                  const location = meeting && meeting["location"]
                  const timeFrame = meeting && meeting["time_frame"].split("-")

                  return (
                    <div key={index} className="event-card meeting-color">
                      <div className="v-line" />
                      <div className="event-icons">
                        <img
                          width="16px"
                          height="16px"
                          src={skipMeeting}
                        />
                        { location ?
                          <img
                            width="16px"
                            height="16px"
                            src={skipLocationLink}
                          />
                        :
                          <img
                            width="16px"
                            height="16px"
                            src={skipLocationOn}
                          />
                        }
                      </div>
                      <div className="event-content">
                        <span className="event-title">{title}</span>
                        <span className="event-location">{location ? location : link}</span>
                      </div>
                      <div className="event-timeframe">
                        <span>Today</span>
                        <span>{timeFrame[0]}</span>
                        <span>-</span>
                        <span>{timeFrame[1]}</span>
                      </div>
                    </div>
                  )
                })
                :
                <div className="no-events">
                  <div className="textlabel-text-group">
                    <div className="textlabel-text8">
                      <span className="textlabel-text-txt">
                        <p className="all-clear">
                          <b>All Clear!</b>
                        </p>
                        <p className="schedules-open-time">
                          Schedule's open. Time for planning!
                        </p>
                      </span>
                    </div>
                    <div className="frame-wrapper4">
                      <div className="wrapper-skip-union-parent">
                        <div className="wrapper-skip-union">
                          <img
                            className="skip-union-icon"
                            alt="skipUnion"
                            src={skipUnion}
                          />
                        </div>
                        <img
                          className="skip-note-stack-icon"
                          alt="skipNoteStack"
                          src={skipNoteStack}
                        />
                      </div>
                    </div>
                  </div>
                  <button className="skip-bmec1">
                    <div className="textlabel-7">New Meeting</div>
                  </button>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  // generate activity counts
  generateWeeklyActivityCounts = (weeklyActivityCounts: any) => {
    const activityCounts = weeklyActivityCounts["activities_count"]
    const currentDate = new Date()
    const currentDayNumber = currentDate.getDay()

    const counts = activityCounts && activityCounts.filter((day:any) =>{
      return day["day_number"] === currentDayNumber
    })
    return counts
  }

  // render activity Card
  renderActivityCard = () => {
    const activityCounts = this.generateWeeklyActivityCounts(this.state.weeklyActivityCounts)

    const dayNumber = activityCounts && activityCounts[0] ? activityCounts[0]["day_number"] : 0
    const meetingsCount = activityCounts && activityCounts[0] ? activityCounts[0]["meetings"] : 0
    const lecturesCount = activityCounts && activityCounts[0] ? activityCounts[0]["lectures"] : 0
    const tasksCount = activityCounts && activityCounts[0] ? activityCounts[0]["tasks"] : 0

    return(
      <div className="view-rectangle group-activity">
        <div className="textlabel-title-container">
          <b className="textlabel-title3">Activity</b>
          <div className="frame-wrapper5">
            <div className="view-stroke-parent">
              <div className="view-stroke2" />
              <div className="textlabel-text9">This week</div>
            </div>
          </div>
        </div>
        <div className="activity-group-switcher">
          <img className="activity-chevron-left"
            width="24px"
            height="24px"
            src={imageChevronLeft}
          />
          <div className="textlabel-text-wrapper1">
            <strong className="textlabel-text10">{dayNumber}</strong>
          </div>
          <div className="textlabel-text10-active-day">
            <b>Day</b>
            <b>Active</b>
          </div>
          <img className="activity-chevron-right"
            width="24px"
            height="24px"
            src={imageChevronRightWhite}
          />
        </div>
        <div className="textlabel-text11">
          You've logged in for one day, keep it up!
        </div>
        <div className="view-rectangle-parent">
          <div className="view-rectangle2" />
          <div className="frame-parent4">
            <div className="skip-meeting-parent">
              <img
                className="skip-meeting"
                alt="skipMeeting"
                src={skipMeeting}
              />
              <b className="textlabel-text12">{meetingsCount}</b>
            </div>
            <div className="textlabel-text13">Meetings</div>
          </div>
          <div className="frame-parent5">
            <div className="skip-lecture-parent">
              <img
                className="skip-lecture-icon"
                alt="skipLecture"
                src={skipLecture}
              />
              <b className="textlabel-text14">{lecturesCount}</b>
            </div>
            <div className="textlabel-text15">Lectures</div>
          </div>
          <div className="frame-parent6">
            <div className="skip-task-parent">
              <img
                className="skip-task-icon"
                alt="skipTask"
                src={skipTask}
              />
              <b className="textlabel-text16">{tasksCount}</b>
            </div>
            <div className="textlabel-text17">Tasks</div>
          </div>
        </div>
      </div>
    )
  }

  renderEventIcon = (type: string, location: string) => {
    if (type == "task") {
      return (
        <img
          width="16px"
          height="16px"
          src={skipCalendar}
        />
      )
    }
    else if (type == "lecture") {
      return (
        <img
          width="16px"
          height="16px"
          src={skipLecture}
        />
      )
    }
    else if (location) {
      return (
        <img
          width="16px"
          height="16px"
          src={skipLocationLink}
        />
      )
    }
    else {
      return (
        <img
          width="16px"
          height="16px"
          src={skipLocationOn}
        />
      )
    }
  }

  // render tomorrow card
  renderTomorrowCard = () => {
    const tomorrowData = this.state.tomorrowEventsData
    const currentPage = 1
    const dataPerPage = 4
    const lastIndex = currentPage * dataPerPage
    const firstIndex = lastIndex-dataPerPage
    const eventsList = tomorrowData && tomorrowData.slice(firstIndex, lastIndex)

    return (
      <div className="view-rectangle3 group-tomorrow">
        <div className="tomorrow-group-parent">
          <div className="frame-parent8">
            <div className="textlabel-title-frame">
              <b className="textlabel-title4">Tomorrow</b>
            </div>
            <div className="frame-wrapper6">
              <div className="view-stroke-group">
                <div className="view-stroke3" />
                <div className="textlabel-text18">0</div>
              </div>
            </div>
            <img
              className="group-switcher-icon"
              alt="groupSwitcher"
              src={groupSwitcher}
            />
          </div>
          <div className="upcomingmeetings-content">
            <div className="upcomingmeetings-list">
              { tomorrowData.length > 0 ?
                  eventsList.map((tomorrowObj: any, index: number) => {
                    const tomorrowEvent = tomorrowObj["data"]["attributes"]
                    const type = tomorrowEvent && tomorrowEvent["task_category_title"]
                    const iconType = type && (type[0].toUpperCase() + type.slice(1))
                    const deadline = tomorrowEvent["deadline"] && tomorrowEvent["deadline"].split(' ')
                    const taskStartTime = deadline && deadline[3] + " " + deadline[4]
                    const eventTimeFrame = tomorrowEvent["time_frame"] && tomorrowEvent["time_frame"].split('-')
                    const location = tomorrowEvent && tomorrowEvent["location"] || ''

                    return (
                      <div key={index} className={`event-card ${type}-color`}>
                        <div className="v-line" />
                        <div className="event-icons">
                          <img
                            width="16"
                            height="16"
                            alt={`skip${iconType}`}
                            src={`skip${iconType}`}
                          />
                          { this.renderEventIcon(type, location) }
                        </div>
                        <div className="event-content">
                          <span className="event-title"> {tomorrowEvent["title"]} </span>
                          { type == "task" ?
                              <div className="event-task-time">
                                <span className="event-location"> {taskStartTime} </span>
                                <img
                                  width="16px"
                                  height="16px"
                                  src={groupPriority}
                                />
                              </div>
                            :
                              <span className="event-location"> {tomorrowEvent["link"]} </span>
                          }
                        </div>
                        { type == "task" ?
                            <div className=" event-timeframe circle">
                              <img
                                width="16px"
                                height="16px"
                                src={skipCircle}
                              />
                            </div>
                          :
                            <div className="event-timeframe">
                              <span>{eventTimeFrame[0]}</span>
                              <span>-</span>
                              <span>{eventTimeFrame[1]}</span>
                            </div>
                        }
                      </div>
                    )
                  })
                :
                  <div className="frame-parent9">
                    <div className="frame-wrapper7">
                      <div className="textlabel-text-parent1">
                        <div className="textlabel-text19">
                          <span className="textlabel-text-txt1">
                            <p className="nothing-on-for-tomorrow-yet">
                              <b>Nothing on for tomorrow yet!</b>
                            </p>
                            <p className="schedule-lectures-meetings">
                              Schedule lectures, meetings, and other
                              learning opportunities.
                            </p>
                          </span>
                        </div>
                        <div className="frame-wrapper8">
                          <div className="skip-union-parent">
                            <img
                              className="skip-union-icon1"
                              alt="skipUnion1"
                              src={skipUnion1}
                            />
                            <img
                              className="skip-calendar-month-icon"
                              alt="skipCalendarMonth"
                              src={skipCalendarMonth}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <button className="skip-bmec3">
                      <div className="image-wrapper">
                        <img
                          className="image-icon"
                          alt="image2"
                          src={image2}
                        />
                      </div>
                      <div className="textlabel-9">New Activity</div>
                      <div className="image-container">
                        <img
                          className="image-icon1"
                          alt="image3"
                          src={image3}
                        />
                      </div>
                    </button>
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  // render categories card
  renderCategoriesCard = () => {
    return (
      <div className="group-categories">
        <div className="view-rectangle4" />
        <div className="frame-parent10">
          <div className="textlabel-title-wrapper1">
            <b className="textlabel-title5">Categories</b>
          </div>
          <div className="group-add">
            <div className="view-stroke4" />
            <div className="textlabel-text20">Add</div>
          </div>
        </div>
        <div className="group-category-parent">
          <div className="group-category">
            <div className="view-rectangle-group">
              <div className="view-rectangle5" />
              <img
                className="skip-mask-icon"
                alt="skipMask2x"
                src={skipMask_2x}
              />
            </div>
            <div className="checkbox-mec-wrapper">
              <img
                className="checkbox-mec-icon"
                alt="checkboxMec"
                src={checkboxMec}
              />
            </div>
            <div className="textlabel-text-wrapper2">
              <div className="textlabel-text21">Meetings</div>
            </div>
            <div className="skip-more-vert-wrapper">
              <img
                className="skip-more-vert-icon"
                alt="skipMoreVert"
                src={skipMoreVert}
              />
            </div>
          </div>
          <div className="group-category1">
            <div className="view-rectangle-container">
              <div className="view-rectangle6" />
              <img
                className="skip-mask-icon1"
                alt="skipMask1_2x"
                src={skipMask1_2x}
              />
            </div>
            <div className="checkbox-mec-container">
              <img
                className="checkbox-mec-icon1"
                alt="checkboxMec1"
                src={checkboxMec1}
              />
            </div>
            <div className="textlabel-text-wrapper3">
              <div className="textlabel-text22">Lectures</div>
            </div>
            <div className="skip-more-vert-container">
              <img
                className="skip-more-vert-icon1"
                alt="skipMoreVert"
                src={skipMoreVert}
              />
            </div>
          </div>
        </div>
        <div className="group-category2">
          <div className="view-rectangle-parent1">
            <div className="view-rectangle7" />
            <img
              className="skip-mask-icon2"
              alt="skipMask2_2x"
              src={skipMask2_2x}
            />
          </div>
          <div className="checkbox-mec-frame">
            <img
              className="checkbox-mec-icon1"
              alt="checkboxMec2"
              src={checkboxMec2}
            />
          </div>
          <div className="textlabel-text-wrapper4">
            <div className="textlabel-text23">Tasks</div>
          </div>
          <div className="skip-more-vert-frame">
            <img
              className="skip-more-vert-icon2"
              alt="skipMoreVert"
              src={skipMoreVert}
            />
          </div>
        </div>
      </div>
    )
  }

  // render calendar day view card
  renderCalendarDayViewCard = () => {
    return (
      <div className="calendar-day">
        <div className="frame-parent11">
          <div className="frame-wrapper9">
            <div className="textlabel-title-parent1">
              <b className="textlabel-title6">
                Thursday, February 8 2024
              </b>
              <div className="skip-world-parent">
                <img
                  className="skip-world-icon"
                  alt="skipWorld"
                  src={skipWorld}
                />
                <div className="textlabel-text-wrapper5">
                  <div className="textlabel-text24">
                    Time zone: EST
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="frame-parent12">
            <div className="group-switcher-wrapper">
              <img
                className="group-switcher-icon1"
                alt="groupSwitcher1"
                src={groupSwitcher1}
              />
            </div>
            <div className="tabbar-segmented-group-mec">
              <div className="skip-day">
                <div className="textlabel-label-">Day</div>
              </div>
              <div className="button-week">
                <div className="textlabel-label-1">Week</div>
              </div>
              <div className="button-month">
                <div className="textlabel-label-2">Month</div>
              </div>
              <div className="button-year">
                <div className="textlabel-label-3">Year</div>
              </div>
            </div>
          </div>
        </div>
        <div className="group-">
          <div className="group-1">
            <div className="textlabel-10">07:00</div>
            <div className="line-wrapper">
              <div className="line-" />
            </div>
            <img
              className="group-current-time-icon"
              alt="groupCurrentTime"
              src={groupCurrentTime}
            />
          </div>
          <div className="group-calendar-hour">
            <div className="textlabel-11">08:00</div>
            <div className="line-container">
              <div className="line-1" />
            </div>
          </div>
          <div className="group-calendar-hour1">
            <div className="textlabel-12">09:00</div>
            <div className="line-frame">
              <div className="line-2" />
            </div>
          </div>
          <div className="group-calendar-hour2">
            <div className="textlabel-13">10:00</div>
            <div className="line-wrapper1">
              <div className="line-3" />
            </div>
          </div>
          <div className="group-calendar-hour3">
            <div className="textlabel-14">11:00</div>
            <div className="line-wrapper2">
              <div className="line-4" />
            </div>
          </div>
          <div className="group-calendar-hour4">
            <div className="textlabel-15">12:00</div>
            <div className="line-wrapper3">
              <div className="line-5" />
            </div>
          </div>
          <div className="group-calendar-hour5">
            <div className="textlabel-16">13:00</div>
            <div className="line-wrapper4">
              <div className="line-6" />
            </div>
          </div>
          <div className="group-calendar-hour6">
            <div className="textlabel-17">14:00</div>
            <div className="line-wrapper5">
              <div className="line-7" />
            </div>
          </div>
          <div className="group-calendar-hour7">
            <div className="textlabel-18">15:00</div>
            <div className="line-wrapper6">
              <div className="line-8" />
            </div>
          </div>
          <div className="group-calendar-hour8">
            <div className="textlabel-19">16:00</div>
            <div className="line-wrapper7">
              <div className="line-9" />
            </div>
          </div>
          <div className="group-calendar-hour9">
            <div className="textlabel-20">17:00</div>
            <div className="line-wrapper8">
              <div className="line-10" />
            </div>
          </div>
          <div className="group-calendar-hour10">
            <div className="textlabel-21">18:00</div>
            <div className="line-wrapper9">
              <div className="line-11" />
            </div>
          </div>
          <div className="group-calendar-hour11">
            <div className="textlabel-22">19:00</div>
            <div className="line-wrapper10">
              <div className="line-12" />
            </div>
          </div>
          <div className="group-calendar-hour12">
            <div className="textlabel-23">20:00</div>
            <div className="line-wrapper11">
              <div className="line-13" />
            </div>
          </div>
        </div>
      </div>
    )
  }

  // render No Tasks card
  renderNoTasks = () => {
    return (
      <div className="group-mytasks-inner">
        <div className="textlabel-text-parent2">
          <div className="textlabel-text28">
            <span className="textlabel-text-txt2">
              <p className="ready-to-tackle-your-first-tas">
                <b>Ready to tackle your first task?</b>
              </p>
              <p className="your-tasks-will">
                Your tasks will start to fill this space! Begin by
                setting your first goal or to-do, and watch your
                productivity grow.
              </p>
            </span>
          </div>
          <div className="frame-wrapper11">
            <div className="skip-union-container">
              <img
                className="skip-union-icon3"
                alt="skipUnion3"
                src={skipUnion3}
              />
              <img
                className="skip-bar-chart-4-bars-icon"
                alt="skipBarChart4Bars"
                src={skipBarChart4Bars}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  // render task element content
  renderTaskElementContent = (data: any) => {
    return (
      <div className="tasks-list-content">
        { data && data.map((taskObj: any, index: number) =>
          {
            // console.log("Task Element", taskObj && taskObj["attributes"])
            const element = taskObj && taskObj["attributes"]
            const description = element && element["description"]
            const deadline = element && element["deadline"]
            const priority = element && element["priority"]

            return(
              <div key={index} className="task-card-container">
                <div className="task-card">
                  <div className="task-element">
                    <div className="task-elements-group-left">
                      <div className="task-element-icon">
                        <img
                          width="16"
                          height="16"
                          alt="skipTaskSummarize"
                          src={skipTaskSummarize}
                        />
                      </div>
                      <div className="task-element-title">
                        <span>{description}</span>
                      </div>
                    </div>
                    <div className="task-elements-group-right">
                      <div className="task-element-deadline">
                        <span>{deadline.split(' ')[3]}</span>
                      </div>
                      <div className="task-element-priority">
                        <img
                          width="22"
                          height="22"
                          alt={`group${priority}TasksPriority`}
                          src={groupLowTasksPriority}
                        />
                      </div>
                      <div className="task-element-status">
                        <img
                          width="16"
                          height="16"
                          alt="skipCircle"
                          src={skipCircle}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        )}
      </div>
    )
  }

  populateTasksList = (myTasksData: any) => {
    return (
      Object.entries(myTasksData).map((taskObj: any, index: number) => {
        const key = taskObj[0].split("_")[0]
        const tasks = taskObj[1]["data"]
        const data = tasks && tasks.slice(0, 5)
        console.log("Today Tasks", taskObj, key, data)
        return (
          key != "month" && (data && data.length > 0) ?
            <div key={index} className={`${key}-tasks-list-container`}>
              <div className="tasks-list-header">
                <div className="tasks-list-title">
                  <span>
                    {key!="today" ? `This ${key}` : "Today"}
                  </span>
                </div>
                <div className="tasks-list-actions">
                  <div>
                    <span>
                      {key=="today" ? "DEADLINE" : null}
                    </span>
                  </div>
                  <div>
                    <span>
                      {key=="today" ? "PRIORITY" : null}
                    </span>
                  </div>
                  <div className="upcomingmeetings-switcher-btns">
                    <img
                      width="24px"
                      height="24px"
                      src={imageChevronLeft}
                    />
                    <img
                      width="24px"
                      height="24px"
                      src={imageChevronRight}
                    />
                  </div>
                </div>
              </div>
              { this.renderTaskElementContent(data) }
            </div>
          :
          null
        )
      })
    )
  }

  // render tasks view dynamically
  renderTasksView = (myTasksData: any, firstIndex: number, lastIndex: number) => {
    return(
      <div className="tasks-list-container">
        { myTasksData ?
          this.populateTasksList(myTasksData)
        : null }
      </div>
    )
  }

  // render tasks card
  renderTasksCard = () => {
    const myTasksData = this.state.myTasksData
    const currentPage = 1
    const dataPerPage = 12
    const lastIndex = currentPage * dataPerPage
    const firstIndex = lastIndex - dataPerPage
    const noTasks = myTasksData.hasOwnProperty("error") ? true : false;

    return (
      <div className="group-mytasks">
        {/*<div className="view-rectangle8" />*/}
        <div className="frame-parent13">
          <div className="frame-parent14">
            <div className="frame-parent15">
              <div className="textlabel-title-wrapper2">
                <b className="textlabel-title7">My Tasks</b>
              </div>
              <div className="mytasks-group-options">
                <img
                  className="group-options-icon"
                  alt="groupOptions2x"
                  src={groupOptions2x}
                />
              </div>
            </div>
            <div className="frame-parent16">
              <div className="frame-wrapper10">
                <div className="skip-union-group">
                  <img
                    className="skip-union-icon2"
                    alt="skipUnion2"
                    src={skipUnion2}
                  />
                  <div className="textlabel-text25">
                    <p className="make-it-happen">
                      <b>Make it happen!</b>
                    </p>
                    <p className="add-a-task">Add a task right away</p>
                  </div>
                </div>
              </div>
              <div className="textinput-fields-parent">
                <div className="textinput-fields">
                  <div className="view-rectangle9" />
                  <input
                    className="textlabel-text26"
                    placeholder="Task title"
                    type="text"
                  />
                </div>
                <textarea
                  className="textinput-fields1"
                  placeholder="Task description (optional)"
                  rows={5}
                  cols={15}
                />
                <div className="textinput-fields">
                  <div className="view-rectangle9" />
                  <input
                    className="textlabel-text26"
                    placeholder="Deadline"
                    type="text"
                  />
                  <img
                    className="skip-arrow"
                    alt="skipArrowDown"
                    src={skipArrowDown}
                  />
                </div>
                <fieldset className="textinput-fields mytasks-select-timezone">
                  <div className="view-rectangle9" />
                  <legend className="mytasks-select-legend"><b>Time Zone</b></legend>
                  <input
                    className="textlabel-text26"
                    placeholder="EST (Default)"
                    type="text"
                  />
                  <img
                    className="skip-arrow"
                    alt="skipArrowDown"
                    src={skipArrowDown}
                  />
                </fieldset>
                <fieldset className="textinput-fields mytasks-select-timezone">
                  <div className="view-rectangle9" />
                  <legend className="mytasks-select-legend"><b>Priority</b></legend>
                  <img
                    className="group-priority"
                    alt="groupHighTasksPriority"
                    src={groupHighTasksPriority}
                  />
                  <input
                    className="textlabel-text26 textlabel-text29"
                    placeholder="High (Default)"
                    type="text"
                  />
                  <img
                    className="mytasks-skip-arrow"
                    alt="skipArrowDown"
                    src={skipArrowDown}
                  />
                </fieldset>
              </div>
            </div>
            <button className="skip-bmec4">
              <div className="textlabel-24">Create New Task</div>
            </button>
          </div>
        </div>
        <div className="mytasks-view-vector">
          <img
              className="skip-vector-icon1"
              alt=""
              src="/skip-vector.svg"
            />
        </div>
        { noTasks ? this.renderNoTasks() : null }
        { myTasksData ? this.renderTasksView(myTasksData, firstIndex, lastIndex) : null}
      </div>
    );
  }

  // Customizable Area End

  render() {
    return (
      <LocalizationProvider>
        {/*this.mySchedule()*/}
        <div className="my-schedule-first-view-of-new">
          <img className="skip-substract-icon" alt="skipSubstract" src={skipSubstract} />
          <main className="frame-parent">
            {this.renderHeader()}
            <section className="frame-container">
              <div className="frame-group">
                <div className="frame-div">
                  <div className="mytools-breadcrumb frame-parent1">
                    <div className="frame-wrapper1">
                      <div className="frame-parent2">
                        <div className="frame-wrapper2">
                          <div className="textlabel-title-parent">
                            <div className="textlabel-title">My Tools</div>
                          </div>
                        </div>
                        <div>
                          <img
                            className="skip-arrow-right-icon"
                            alt="skipArrowRight"
                            src={skipArrowRight}
                          />
                        </div>
                        <div className="frame-wrapper2">
                          <div className="textlabel-title-wrapper">
                            <div className="textlabel-title1">My Schedule</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <button className="skip-bmec4">
                        <div className="image-wrapper">
                          <img
                            className="image-icon"
                            alt="image2"
                            src={image2}
                          />
                        </div>
                        <div className="textlabel-24">New Activity</div>
                        <div className="image-container">
                          <img
                            className="image-icon1"
                            alt="image3"
                            src={image3}
                          />
                        </div>
                      </button>
                    </div>
                  </div>
                  <img
                    className="view-vector-icon"
                    alt="viewVector"
                    src={viewVector}
                  />
                  <div className="my-schedule-highlights frame-parent3">
                    <div className="frame-wrapper3">
                      <div className="calendar-simple-parent">
                        {this.renderCalendarCard()}
                        {this.renderUpcomingMeetingsCard()}
                        {this.renderActivityCard()}
                      </div>
                    </div>
                    <button className="skip-bmec2">
                      <b className="textlabel-8">?</b>
                    </button>
                  </div>
                  <div className="frame-parent7">
                    <div className="group-tomorrow-parent">
                      {this.renderTomorrowCard()}
                      {this.renderCategoriesCard()}
                    </div>
                    {this.renderCalendarDayViewCard()}
                  </div>
                </div>
                {this.renderTasksCard()}
              </div>
            </section>
          </main>
          {this.renderFooter()}
        </div>
      </LocalizationProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
